import Logo from '../../assets/Image-not-found.webp'
import React, { useEffect, useState } from 'react'
import { Suspense } from 'react'
import SkeletonLoading from '../common/SkeletonLoading'
import Evaluation from '../Evaluation'
import { EnterpriseData } from '../../interfaces/enterprise/enterpriseData'
import IconStarFull from '../../assets/icons/icon-star-full.svg'
import IconLoading from '../../assets/loading-gif.gif'
import builderApi from '../../store/reducers/builders/builderApi'

interface Props {
  enterprise: EnterpriseData
}
interface EvaluationProps {
  title: string
  evaluation: number
}

export function AboutCompany({ enterprise }: Props) {
  const [validUrlImg, setValidUrlImg] = useState('')
  const [useBuilderComment, { data, isLoading, isSuccess, isError }] =
    builderApi.useGetCommentBuilderMutation()
  const [
    useBuilderReviews,
    {
      data: dataReviews,
      isLoading: isLoadingReviews,
      isSuccess: isSuccessReviews,
      isError: isErrorReviews
    }
  ] = builderApi.useGetReviewsBuilderMutation()

  const getReviewsBuilder = async () => {
    await useBuilderReviews(enterprise.ConstructionCompanyId)
  }
  const getCommentBuilder = async () => {
    await useBuilderComment(enterprise.Id)
  }

  useEffect(() => {
    getCommentBuilder()
    getReviewsBuilder()
  }, [])

  function checkImagemBanner() {
    const img = new Image()
    img.src = enterprise?.ConstructionCompanyLogoUrl ?? ''
    img.onload = () => {
      setValidUrlImg(enterprise?.ConstructionCompanyLogoUrl ?? '')
    }
    img.onerror = () => {
      setValidUrlImg(Logo)
    }
  }

  useEffect(() => {
    checkImagemBanner()
  }, [enterprise])

  const getScoreBuilder = () => {
    let sumComments = data?.Data.map((x) => x.AverageScore).reduce(
      (partialSum, a) => partialSum + a,
      0
    )
    return parseFloat((sumComments ?? 1).toString()) / parseFloat((data?.Data.length ?? 1).toString())
  }

  return (
    <>
      {!isError && (
        <div className="">
          {/* SOBRE A EMPRESA                */}
          <h2 className="font-bold text-xl md:text-2xl mt-5 text-[#4D4D4D]">Sobre a empresa</h2>
          {isLoading && (
            <div>
              <SkeletonLoading height={400} />{' '}
            </div>
          )}
          {isSuccess && (
            <>
              <div className="mt-8">
                <div className="">
                  <div className="">
                    <div
                      className="border-1 border-gray-200 lg:py-10 rounded-lg h-full aspect-square lg:aspect-auto"
                      style={{ alignContent: 'center' }}
                    >
                      <div className="text-center">
                        <a href={`/construtora/${enterprise.ConstructionCompanyId}`}>
                          <div
                            className="rounded-full border-1 border-gray-100 w-fit m-auto text-center align-middle flex px-1"
                            style={{ aspectRatio: '1/1' }}
                          >
                            <Suspense fallback={<SkeletonLoading />}>
                              <img
                                src={validUrlImg}
                                className="w-full ratio-square rounded-full max-w-[100px] m-auto inline-block"
                              />
                            </Suspense>
                          </div>
                        </a>
                        <h2 className="text-2xl font-semibold text-[#45464E] mt-4">
                          {enterprise.ConstructionCompany}
                        </h2>
                        <div className="flex text-center m-auto justify-center mt-5">
                          <h4 className="font-bold text-xl my-auto">Nota geral</h4>
                          <>
                            <div className="ps-4">
                              <img
                                alt="Star Icon"
                                className={`w-auto inline-block  max-w-full`}
                                src={IconStarFull}
                              />
                              <span
                                className="text-caption inline-block ps-2"
                                style={{ verticalAlign: 'middle' }}
                              >
                                <span className="text-3xl font-bold pe-1">
                                  {getScoreBuilder().toFixed(1)}
                                </span>{' '}
                                {isSuccessReviews && (
                                  <>
                                    {dataReviews?.Data?.TotalReviews ?? 0 > 0
                                      ? `(${dataReviews?.Data?.TotalReviews ?? 0} Reviews)`
                                      : ''}
                                  </>
                                )}
                              </span>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
                    {data?.Data.map((item) => {
                      return (
                        <>
                          <div className="border-1 border-gray-200 p-5 rounded-md">
                            <h2 className="text-2xl font-semibold">{item.Quest}</h2>
                            {/* <p className="text-caption font-[500] opacity-[0.6]">
                              (Em uma escala de 1 a {Math.ceil(item.AverageScore)}, sendo 1 muito
                              insatisfeito e {Math.ceil(item.AverageScore)} muito satisfeito)
                            </p> */}
                            <div className="float-left mt-3">
                              <Evaluation
                                nota={Math.ceil(item.AverageConstruction)}
                                max={Math.ceil(item.AverageScore)}
                              />
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                  {/* {data?.Data.length > 3 && (
                      <>
                        {data?.Data.slice(3, data?.Data.length).map((item) => {
                          return (
                            <>
                              <div className="border-1 border-gray-200 p-5 rounded-md">
                                <h2 className="text-2xl font-semibold">{item.Quest}</h2>
                                <p className="text-caption font-[500] opacity-[0.6]">
                                  (Em uma escala de 1 a {item.AverageScore}, sendo 1 muito
                                  insatisfeito e 5 muito satisfeito)
                                </p>
                                <Evaluation
                                  nota={item.AverageConstruction}
                                  max={item.AverageScore}
                                />
                              </div>
                            </>
                          )
                        })}
                      </>
                    )} */}
                </div>
              </div>
            </>
          )}
          <hr className=" z-[-1] relative opacity-10 mt-4" />
        </div>
      )}
    </>
  )
}
